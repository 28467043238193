const users = [
  {
    photo: "./../../img/image-tanya.jpg",
    name: ".",
    title: ".",
    testimonial:
      "We are reaching out to our alumni community to gather testimonials about their experiences and achievements since graduating from our institution.",
  },

  {
    photo: "./../../img/image-john.jpg",
    name: ".",
    title: ".",
    testimonial:
      "We are reaching out to our alumni community to gather testimonials about their experiences and achievements since graduating from our institution.",
  },
];

export default users;
